











































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
@Component
export default class VoteConfirmSettingsModal extends Vue {
  @Prop(Boolean) withSettings!: boolean
  @Prop(Boolean) withTimeParams!: boolean
  @Prop(Boolean) isInputReady!: boolean
  @Prop(Boolean) withTextParams!: boolean
  @Prop(Array) optionsSetting!: any[]
  @Prop(Array) optionsTimeSetting!: any[]
  @Prop(String) timeParamsType!: 'time' | 'date' | 'datetime'
  @Prop(String) changedTimeVariant!: string
  @Prop(String) changedInfoVariant!: string
  @Prop(Object) voting!: any | null

  private withSettingsUpdate = false
  private withTimeParamsUpdate = false
  private isInputReadyUpdate = false
  private withTextParamsUpdate = false
  private optionsSettingUpdate: any[] = []
  private optionsTimeSettingUpdate: any[] = []
  private changedTimeVariantUpdate = ''
  private changedInfoVariantUpdate = ''
  private votingUpdate: any = null
  private timeParamsTypeUpdate: 'time' | 'date' | 'datetime' = 'date'

  private created() {
    this.withSettingsUpdate = this.withSettings
    this.withTimeParamsUpdate = this.withTimeParams
    this.isInputReadyUpdate = this.isInputReady
    this.withTextParamsUpdate = this.withTextParams
    this.optionsSettingUpdate = this.optionsSetting
    this.optionsTimeSettingUpdate = this.optionsTimeSetting
    this.changedTimeVariantUpdate = this.changedTimeVariant
    this.changedInfoVariantUpdate = this.changedInfoVariant
    this.votingUpdate = this.voting
    this.timeParamsTypeUpdate = this.timeParamsType
  }

  @Watch('voting')
  private votingChange() {
    this.votingUpdate = this.voting
  }

  private reloadVote() {
    this.$emit('reloadVoteInfo', {
      withSettings: this.withSettingsUpdate,
      withTimeParams: this.withTimeParamsUpdate,
      isInputReady: this.isInputReadyUpdate,
      withTextParams: this.withTextParamsUpdate,
      optionsSetting: this.optionsSettingUpdate,
      optionsTimeSetting: this.optionsTimeSettingUpdate,
      timeParamsType: this.timeParamsType,
      changedTimeVariant: this.changedTimeVariantUpdate,
      changedInfoVariant: this.changedInfoVariantUpdate,
      voting: this.votingUpdate
    })
  }

  private onSubmit(evt: any) {
    this.reloadVote()
    this.$emit('onSubmit', evt)
  }

  private onReset(evt: any) {
    this.reloadVote()
    this.$emit('onReset', evt)
  }

  private onChangeTimeParamsType() {
    this.reloadVote()
    this.$emit('onChangeTimeParamsType')
  }

  private deleteParamsVariant(field: number, index: any) {
    this.isInputReadyUpdate = false
    this.votingUpdate.settings[field].splice(index, 1)
    this.isInputReadyUpdate = true
    this.reloadVote()
  }

  private addTimeVariant(field: number) {
    if (_.isArray(this.votingUpdate.settings[field])) {
      this.votingUpdate.settings[field].push(this.changedTimeVariantUpdate)
    } else {
      this.votingUpdate.settings[field] = [this.changedTimeVariantUpdate]
    }
    this.changedTimeVariantUpdate = ''
    this.reloadVote()
  }

  private addInfoVariant() {
    if (_.isArray(this.votingUpdate.settings.fieldsNew)) {
      this.votingUpdate.settings.fieldsNew.push(this.changedInfoVariantUpdate)
    } else {
      this.votingUpdate.settings.fieldsNew = [this.changedInfoVariantUpdate]
    }
    this.changedInfoVariantUpdate = ''
    this.$forceUpdate()
    this.reloadVote()
  }

  private isAddFieldMode() {
    this.reloadVote()
    this.$emit('isAddFieldMode')
  }

  private saveVote() {
    this.reloadVote()
    this.$emit('saveVote')
  }
}
